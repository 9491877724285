const isDeveloperMode = process.env.NODE_ENV !== 'production';
const url = isDeveloperMode ? 'http://localhost:8041' : 'https://core.pastilapp.ru';

const API = {
	url			: `${url}/api/v1/public/`,
	panel		: 'https://partners.pastilapp.ru/',
	key			: 'xR68ATh)P2Rf8j1x-vgQZgq+g:c,p!ZEqsfGRECR_=Z0~sCk)3NbVsxWgaDg-Wo7',
	version		: '1.0.1'
};

const httpMethods = {
	GET		: 'GET',
	POST	: 'POST',
	PUT		: 'PUT',
	DELETE	: 'DELETE'
};


export {
	API,
	httpMethods
};
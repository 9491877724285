import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

// screens
import Start	from './Screens/Start';
import Docs1	from './Screens/Docs1';
import Docs2	from './Screens/Docs2';
import Error	from './Screens/Error';

// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={Start} exact />

			<Route path='/politika-konfidencialnosti' component={Docs1} exact />
			<Route path='/soglashenie' component={Docs2} exact />

			<Route path='/error' component={Error} status={500} exact />
			<Route path='/error401' component={Error} status={401} exact />
			<Route path='/error403' component={Error} status={403} exact />
			<Route path='/error404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />

		</Switch>
	</BrowserRouter>
);

export default App;